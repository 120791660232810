* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;

}
body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;

}

.gradient__bg {
    
        /* global 94%+ browsers support */
        /* background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 37, 120, 1) 35%, rgba(6, 0, 20, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 32, 133, 1) 69%, rgba(0, 0, 0, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 32, 133, 1) 56%, rgba(0, 32, 133, 1) 60%, rgba(0, 0, 0, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 32, 133, 1) 25%, rgba(0, 29, 120, 1) 75%, rgba(0, 0, 0, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 25, 103, 1) 29%, rgba(0, 23, 93, 1) 71%, rgba(0, 23, 93, 1) 74%, rgba(0, 0, 0, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 25, 103, 1) 29%, rgba(0, 23, 93, 1) 99%, rgba(0, 0, 0, 1) 100%, rgba(0, 23, 93, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 20, 83, 1) 50%, rgba(0, 0, 0, 1) 100%, rgba(0, 23, 93, 1) 100%, rgba(0, 19, 76, 1) 100%); */
        /* background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 15, 63, 1) 50%, rgba(0, 0, 0, 1) 100%, rgba(0, 23, 93, 1) 100%, rgba(0, 19, 76, 1) 100%); */
        background: linear-gradient(90deg, rgba(0, 5, 30, 1) 0%, rgba(0, 13, 53, 1) 50%, rgba(0, 0, 0, 1) 100%, rgba(0, 23, 93, 1) 100%, rgba(0, 19, 76, 1) 100%);
}


.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}
.section__margin {
    margin: 4rem 6rem;
}
.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@media screen and (max-width: 700px){
    .section__padding{
        padding: 4rem;
    }
    .section__margin{
        padding: 4rem;
    }
}
@media screen and (max-width: 550px){
    .section__padding{
        padding: 4rem 2rem;
    }
    .section__margin{
        padding: 4rem 2rem;
    }
}

