@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,400;0,500;0,600;0,800;1,900&display=swap');

*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: var(--font-family);
}
body{
	width: 100%;
	min-height: 100vh;
	background-color: #2e2e2e;
	color: white;
    font-family: var(--font-family);
}
.container{
	width: 100%;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 8%;
}
.container .row{
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1100px;
}
.row section.col{
	display: flex;
	flex-direction: column;
}
.row section.left{
	flex-basis: 35%;
	min-width: 320px;
	margin-right: 60px;
}
.row section.right{
	flex-basis: 60%;
}
section.left .contactTitle h2{
	position: relative;
	font-size: 28px;
	color: #ddd;
	display: inline-block;
	margin-bottom: 25px;
}
section.left .contactTitle h2::before{
	content: '';
	position: absolute;
	width: 50%;
	height: 1px;
	background-color: #888;
	top: 120%;
	left: 0;
}
section.left .contactTitle h2::after{
	content: '';
	position: absolute;
	width: 25%;
	height: 3px;
	background-color: dodgerblue;
	top: calc(120% - 1px);
	left: 0;
}
section.left .contactTitle p{
	font-size: 17px;
	color: #ccc;
	letter-spacing: 1px;
	line-height: 1.2;
	padding-bottom: 22px;
}
section.left .contactInfo{
	margin-bottom: 16px;
}
.contactInfo .iconGroup{
	display: flex;
	align-items: center;
	margin: 25px 0px;
}
.icon {
    width: 45px;
    height: 45px;
    border: 2px solid dodgerblue;
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
	cursor: pointer;
  }
.social-icons{
    display: flex;
}  

.iconGroup .details span{
	display: block;
	color: #888;
	font-size: 18px;
}
.iconInner {
    font-size: 20px;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the inner icon takes full width */
    height: 100%; /* Ensure the inner icon takes full height */
  }
.iconGroup .details span:nth-child(1){
	text-transform: uppercase;
	color: #ccc;
}
section.left .socialMedia{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin: 22px 0px 20px;
}

.socialMedia a:hover i{
	color: dodgerblue;
}

/* Code for the right section (column) */

.row section.right .messageForm{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 30px;
}
.row section.right .inputGroup{
	margin: 18px 0px;
	position: relative;
}
.messageForm .halfWidth{
	flex-basis: 48%;
}
.messageForm .fullWidth{
	flex-basis: 100%;
}
.messageForm input, .messageForm textarea{
	width: 100%;
	font-size: 16px;
	padding: 10px 0; /* Adjust padding as needed */
	border: none;
	border-bottom: 2px solid #ccc; /* Bottom border to give a line-like appearance */
	outline: none;
	background: none;
	color: white; 
}
.inputGroup textarea {
	height: 150px;
	resize: none;
	padding-top: 10px; /* Adjust padding for the textarea */
  }
textarea::-webkit-scrollbar{
	width: 5px;
}
textarea::-webkit-scrollbar-track{
	background-color: #1e1e1e;
	border-radius: 15px;
}
textarea::-webkit-scrollbar-thumb{
	background-color: dodgerblue;
	border-radius: 15px;
}
.inputGroup label{
	position: absolute;
	left: 0;
	bottom: 4px;
	color: #888;
	font-size: 18px;
	transition: 0.4s;
	pointer-events: none;
}
.inputGroup:nth-child(4) label{
	top: 2px;
}
.inputGroup input:focus ~ label, .inputGroup textarea:focus ~ label,
.inputGroup input:valid ~ label, .inputGroup textarea:valid ~ label
{
	transform: translateY(-30px);
	font-size: 16px;
}
.inputGroup button{
	padding: 8px 16px;
	font-size: 18px;
	background-color: dodgerblue;
	color: #ddd;
	border: 1px solid transparent;
	border-radius: 25px;
	outline: none;
	cursor: pointer;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
	transition: 0.4s;
}
.inputGroup button:hover{
	background-color: #2e2e2e;
	color: dodgerblue;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
	border: 1px solid dodgerblue;
}

.saysomething{
	height: 140px;
}
.icon {
    font-size: 40px; /* Adjust the size as needed */
    color: white; /* Color of the icon */
     /* Background color */
    padding: 10px; /* Adjust padding as needed */
    border-radius: 50%; /* Make it circular */
  }
  
  .icon-link {
    margin-right: 10px; /* Adjust margin between icons */
  }
  
  /* Additional styling for hover effect */
  .icon:hover {
    background-color: #1877f2; /* Change background color on hover */
  }

@media(max-width: 1100px){
	.messageForm .halfWidth{
		flex-basis: 100%;
	}
}
@media(max-width: 900px){
	.container .row{
		flex-wrap: wrap;
	}
	.row section.left, .row section.right{
		flex-basis: 100%;
		margin: 0px;
	}
}