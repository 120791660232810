.gpt3__brand{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    padding: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.gpt3__brand div img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    max-height: 100%; /* Ensure the image doesn't exceed its container */
}