.gpt3__header{
    display: flex;
}
.gpt3__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;

}
.gpt3__header-content__input {
    width: 60%;
    margin: 2rem 0 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;


}
/* .gpt3__header-content__input input{
    flex: 2;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
   
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

} */
.gpt3__header-content__input button{
    flex: 0.4;
    width: 100%;
    min-height: 50px;
    background: rgba(3, 112, 155, 0.369);
    
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba(130, 2, 222, 0.593);
    /* border-radius: 5px 0px 0px 5px; */
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
    border: none;
    border-radius: 10px;
}
.gpt3__header-content__input button:hover {
    /* color: red !important;  */
    /* border: 1px solid rgba(130, 2, 222, 0.676); */
    transition: width 0.5s, border-color 0.5s; 
    background: rgba(3, 112, 155, 0.815);
    /* border-color: rgb(140, 0, 255); */
  }
.card-front{
    display: flex;
    

    background: rgba(0, 0, 0, 0.369);
    
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
    border-radius: 10px;
    border: 1px solid rgba(130, 2, 222, 0.593);
    }
.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1rem;
}
/* .gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
    
} */
/* .gpt3__header-content__people p {
    font-family: var(--font-family);
    margin: 0 0 0 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #FFFFFF;
    text-align: center;
  
} */
.gpt3__header-content__people .iconinner {
   
        width: 45px;
        height: 45px;
        border: 2px solid dodgerblue;
        border-radius: 50%;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        padding: 0.5rem;
      /* Ensure the inner icon takes full height */
  }
  
.gpt3__header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}
.gpt3__header-image img {
    width: 100%;
    height: 100%;

    /* filter: brightness(80%);  */
    transition: filter 0.5s ease; /* Smooth transition for the filter */
    /* border-radius: 50%; */
    /* -webkit-mask-image: radial-gradient(ellipse at center, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    mask-image: radial-gradient(ellipse at center, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%); */
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%); /* Fading mask from top to bottom */
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
    filter: grayscale(0%) brightness(85%);

}
/* .gpt3__header-image:hover img {
    filter: grayscale(100%) brightness(70%);/* Adjust values as needed */
/* } */ 

@media screen and (max-width: 1050px) {
    .gpt3__header{
        flex-direction: column;
    }
    .gpt3__header-content{
        margin: 0 0 3rem;
    }
    .gpt3__header-image img{
        display: none;
    }
    .gpt3__header-content__people .iconinner {
   
        width: 40px;
        height: 40px;
        border: 2px solid dodgerblue;
        border-radius: 50%;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        padding: 0.5rem;
    }

}
@media screen and (max-width: 650px) {
    .gpt3__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }
    .gpt3__header-content__people{
        flex-direction: row;
    }
    .gpt3__header-content__people p{
        margin: 0;
    }
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }


}

@media screen and (max-width: 490px) {
    .gpt3__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }
    /* .gpt3__header-content__people{
        flex-direction: column;
    }
    .gpt3__header-content__people p{
        margin: 0;
    } */
    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people .iconinner {
   
        width: 38px;
        height: 38px;
        border: 2px solid dodgerblue;
        border-radius: 50%;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        padding: 0.5rem;
    }

}